<template>
	<div class="card">
		<Card>
			<template #title>
				<h4>{{$t(title)}}</h4>
			</template>
			<template #content>
                <div class="p-grid">
                    <div class="p-col-12" v-if="i18n.locale() == 'es'">
						{{$t('Tipo de Vehículo')}}: <strong>{{detalle.tipovehnombre}}</strong> 			
					</div>
					<div class="p-col-12" v-else>
                        {{$t('Tipo de Vehículo')}}: <strong>{{detalle.tipovehname}}</strong> 			
					</div>
					<div class="p-col-6">
						{{$t('Marca')}}: <strong>{{detalle.marca}}</strong>			
					</div>
					<div class="p-col-6">
						{{$t('Módelo')}}: <strong>{{detalle.modelo}}</strong>			
					</div>
					<div class="p-col-6">
						{{$t('Año de Compra')}}: <strong>{{detalle.veh_compra}}</strong>			
					</div>
					<div class="p-col-6">
						{{$t('Número de motor')}}: <strong>{{detalle.veh_motor}}</strong>
					</div>
					<div class="p-col-6">
						{{$t('Número de chasis')}}: <strong>{{detalle.veh_chasis}}</strong>
					</div>
					<div class="p-col-6">
                        {{$t('Nº placa')}}: <strong>{{detalle.veh_placa}}</strong>
                    </div>
                </div>
			</template>
		</Card>
	</div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
        props: {
            detall: {
                type: Object,
                default: null,
            },
            datos: {
                type: Object,
                default: null,
            },
        },
        data() {
			return {
				i18n: null,
                title: null,
                inf: this.datos,
                detalle: this.detall,
			}
		},
		created() {
			this.i18n = useI18nPlugin();
            switch (this.datos.tipo) {
                case 2:
                    this.title = 'Información del Vehículo';
                    break;
            }
		},
        methods: {

        }
	}
</script>
